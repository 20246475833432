"use client"
import { Grid, Card, Typography, Button, Paper, Divider } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import InputField from "../../component/InputField";
import rupee1 from '../../Assests/rupee1.svg';
import download from '../../Assests/download.svg';
import wallet from "../../Assests/Walletcircle.svg";
import walletIcon from "../../Assests/WalletIcon.svg";
// import Image from 'next/image';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { createPaymentOrder, getWalletHistory, verifyPaymentSignature } from '../../Helpers/Apiservice';
import Loader from '../../component/Loader';
import SnackPopup from '../../component/SnackPopup';
import { useInformationStore, useAuthStore } from '../../Helpers/store/loginStore';
import isAuth from '../../Helpers/isAuth';
import Button1 from "../../component/Button/index";
import { IwalletData } from '../../shared/Wallet';
import { useNavigate } from 'react-router-dom';
import "./index.css";

interface WalletData {

}

const Page = () => {
  const [data, setData] = useState<IwalletData[] | []>();
  const [amount1, setAmount1] = useState<number | string>("");
  const [finalAmount, setFinalAmount] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [enableDownload, setEnableDownload] = useState<boolean>(true);
  const [enableAdd, setEnableAdd] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const { environment } = useInformationStore();
  const { amount } = useAuthStore();

  const router = useNavigate();

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddAmount = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event.target.value.trim(); // Remove leading and trailing whitespace
    const Val = Number(input);
    if (!isNaN(Val)) {
      let tempAmount;
      const withGST = Number((Val * 0.18).toFixed(2));
      setFinalAmount(Number((Val - withGST).toFixed(2)));
      // console.log();

      setAmount1(Val);
      tempAmount = Val;
      if (tempAmount !== 0) {
        setEnableAdd(false);
      } else {
        setEnableAdd(true);
      }
    } else {
      // If input is not a valid number, handle accordingly (e.g., show error message)
      // For example:
      // setError("Please enter a valid number");
      // You can set this error state to display an error message to the user.
    }
  }

  function convertTimestamp(timestamp: string): string {
    const date = new Date(timestamp);

    // Get components of the date
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().substr(-2);


    // Format the date and time
    const formattedDate = `${hours}:${minutes}:${seconds}, ${day} ${month} ${year}`;

    return formattedDate;
  }

  const isValidAmount = (amount: number) => amount < 0;


  const column: GridColDef[] = [
    {
      field: "amount",
      headerName: "Amount",
      // width: 250,
      flex: 1,

      renderCell: (params) => <div>₹{params.value}</div>,
    },
    // {
    //   field: "transaction_type",
    //   headerName: "Transaction Type",
    //   width: 250,
    // },
    {
      field: "transaction_status",
      headerName: "Transaction Status",
      // width: 250,
      flex: 1,
      renderCell: (params) => (
        <div> {params.value == "COMPLETED" ? <div style={{ color: "#2E7D32", fontWeight: 800 }}>Success</div> : <div style={{ color: "#9A1E1E", fontWeight: 800 }}>Failed</div>}</div>
      )
    },
    {
      field: "created_at",
      headerName: "TimeStamp",
      flex: 1,
      // width: 250
    }
  ];


  useEffect(() => {
    const razorPayResponse = loadScript('https://checkout.razorpay.com/v1/checkout.js');
    setLoading(true);
    let payload = { customer_id: "cust_LJr7gSOsfEkMkY" }
    getWalletHistory(payload)
      .then((res: any) => {
        let response: IwalletData[] = res.data.map((item: IwalletData, index: number) => {
          return {
            ...item,
            id: index,
            created_at: convertTimestamp(item.created_at)
          };
        });
        response.reverse();
        if (response.length >= 1) {
          setLoading(false);
          setEnableDownload(false);
          setData(response);
        } else {
          // handleSnackbarOpen()
          // setSnackbarMessage("No Transcation History")
          setLoading(false);
          setData([])
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
  }, [environment, refresh]);


  const getTransactionVerificationStatus = async (requestBody: {
    razorpay_payment_id: string
    razorpay_order_id: string
    razorpay_signature: string
  }) => {
    let payload = {
      client_id: 'abcdef',
      razorpay_payment_id: requestBody?.razorpay_payment_id,
      order_id: requestBody?.razorpay_order_id,
      razorpay_signature: requestBody?.razorpay_signature
    }

    verifyPaymentSignature(payload)
      .then((res: any) => {
        console.log(res, "dftyuiop");
      })
      .catch((err: any) => {
        console.log(err, "dfghjkl;");
      })
  }
  const loadScript = (src: string) => {
    return new Promise(function (resolve) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      script.async = true
      script.onerror = function () {
        resolve(false)
      }
      script.onload = function () {
        resolve(true)
      }
      document.body.appendChild(script)
    })
  }

  // const handlePaymentOrderCreation = async () => {
  //   let response;
  //   if (finalAmount <= 0 || finalAmount == null ||  finalAmount == "" || finalAmount == undefined) {
  //     handleSnackbarOpen()
  //     setSnackbarMessage("Invalid Amount")
  //   } else {
  //     let payload_amount = (finalAmount *100).toFixed(1) 
  //     console.log(Number(payload_amount));


  //     const payload = {
  //       amount: Number(payload_amount),
  //       currency: 'INR',
  //       notes: {
  //         notes_key_1: 'Tea, Earl Grey, Hot Radhe ',
  //         notes_key_2: 'Tea, Earl Grey… decaf. Tiwari'
  //       }
  //     }
  //     let orgId = environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId");
  //     setLoading(true);
  //     createPaymentOrder(orgId, payload)
  //       .then((res :any) => {
  //         if (res.code != 200) {
  //           handleSnackbarOpen()
  //           setSnackbarMessage("Error: create order.")
  //           setLoading(false);
  //         } else {
  //           // debugger
  //           let razor = res
  //           // setRazorPay(res);
  //           const razorPayResponse = loadScript('https://checkout.razorpay.com/v1/checkout.js')

  //           if (!razorPayResponse) {
  //             alert('You are offline...  Failed to load RazorPay SDK')
  //             return
  //           }

  //           const options = {
  //             key: process.env.NEXT_PUBLIC_PAYMENT_GATEWAY_SECRET_KEY,
  //             currency: 'INR',
  //             amount: razor?.data?.amount,
  //             name: 'Pichain Innovation Labs',
  //             description: 'Test Transaction',
  //             image: 'https://i.postimg.cc/cHVgHcb7/logo.png',
  //             order_id: razor?.data?.id,
  //             handler: function (response: { razorpay_payment_id: string; razorpay_order_id: string; razorpay_signature: string }) {
  //               // setPaymentStatus({
  //               //   payment_id: response?.razorpay_payment_id,
  //               //   order_id: response?.razorpay_order_id,
  //               //   signature: response?.razorpay_signature
  //               // })
  //               getTransactionVerificationStatus(response);
  //               if (response.razorpay_payment_id) {
  //                 // Payment successful, redirect or reload
  //                 // router.push('/Dashboard'); // Redirect to a 'Thank You' page
  //                 // setRefresh(true)
  //                 // OR
  //                 window.location.reload();// Reload the current page
  //               } else {
  //                 // Payment failed, handle accordingly
  //                 alert("Payment failed!");
  //               }

  //             },
  //             prefill: {
  //               name: 'test 1', //your customer's name
  //               email: 'prasanna.p@gmail.com',
  //               contact: '9986245959'
  //             },
  //             notes: {
  //               address: 'Pichain test'
  //             },
  //             theme: {
  //               color: (theme: { palette: { primary: string } }) => theme.palette.primary
  //             },
  //           }
  //           const paymentObject = window && (window as any)?.Razorpay(options);
  //           paymentObject.open();
  //           setLoading(false);
  //         }
  //       }).catch((err) => {
  //         // console.log(err, "ghjkjhvgbhjnk");
  //         if(err){
  //           // handleSnackbarOpen()
  //           // setSnackbarMessage("Internal Server Error")
  //           setLoading(false);
  //         }

  //       })

  //   }
  // }

  const handlePaymentOrderCreation = async () => {
    let response;
    if (finalAmount <= 0 || finalAmount == null || finalAmount == "" || finalAmount == undefined) {
      handleSnackbarOpen();
      setSnackbarMessage("Invalid Amount");
    } else {
      let payload_amount = (finalAmount * 100).toFixed(1);
      console.log(Number(payload_amount));

      const payload = {
        amount: Number(payload_amount),
        currency: 'INR',
        notes: {
          notes_key_1: 'Tea, Earl Grey, Hot Radhe ',
          notes_key_2: 'Tea, Earl Grey… decaf. Tiwari'
        }
      };
      let orgId = environment ? sessionStorage.getItem("live_orgId") : sessionStorage.getItem("test_orgId");
      setLoading(true);
      createPaymentOrder(orgId, payload)
        .then((res: any) => {
          if (res.code != 200) {
            handleSnackbarOpen();
            setSnackbarMessage("Error: create order.");
            setLoading(false);
            handleSnackbarOpen();
            setSnackbarMessage(res);
          } else {
            let razor = res;
            const razorPayResponse = loadScript('https://checkout.razorpay.com/v1/checkout.js');

            if (!razorPayResponse) {
              alert('You are offline...  Failed to load RazorPay SDK');
              return;
            }

            const options = {
              key: process.env.NEXT_PUBLIC_PAYMENT_GATEWAY_SECRET_KEY,
              currency: 'INR',
              amount: razor?.data?.amount,
              name: 'Pichain Innovation Labs',
              description: 'Test Transaction',
              image: 'https://i.postimg.cc/cHVgHcb7/logo.png',
              order_id: razor?.data?.id,
              handler: function (response: { razorpay_payment_id: string; razorpay_order_id: string; razorpay_signature: string }) {
                getTransactionVerificationStatus(response);
                if (response.razorpay_payment_id) {
                  window.location.reload();
                } else {
                  alert("Payment failed!");
                }
              },
              prefill: {
                name: 'test 1',
                email: 'prasanna.p@gmail.com',
                contact: '9986245959'
              },
              notes: {
                address: 'Pichain test'
              },
              theme: {
                color: (theme: { palette: { primary: string } }) => theme.palette.primary
              },
            };
            const paymentObject = window && (window as any)?.Razorpay(options);
            paymentObject.open();
            setLoading(false);

          }
        }).catch((err: any) => {
          // Uncomment this block to handle errors
          // handleSnackbarOpen();
          // setSnackbarMessage("Internal Server Error");
          setLoading(false);
        });
    }
  };




  return (
    <div >
      <Loader loading={loading} />
      <SnackPopup message={snackbarMessage}
        color="error"
        open={snackbarOpen}
        handleClose={handleSnackbarClose} />
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <div className='h-100 w-100' style={{ backgroundColor: '#1E5198', borderRadius: '16px' }}>,
            <div className='d-flex justify-content-between'>
              <div className='wallet-banner' >
                <img
                  src={wallet} // Replace './wallet.png' with the path to your first image
                  style={{ position: 'absolute', width: '100%', height: '100%' }}
                  alt='rupee'
                />
                <img
                  src={walletIcon}
                  style={{ position: 'absolute', top: 24, left: 13, }}
                  alt='rupee'
                />
              </div>
              <div className='mx-auto mt-2'>
                <Typography className='text-white'>Available Balance </Typography>
                <div className='d-flex align-items-center gap-3'>
                  <img src={rupee1} alt='rupee' /><Typography className='text-white fw-bold' sx={{ fontSize: '42px' }}>{amount}</Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={8} xs={12}>
          <Card elevation={0} className='p-4 rounded-4'>
            <Typography className="fw-bold" sx={{ fontSize: "20px" }}>Wallet Recharge</Typography>

            <Grid container spacing={2} >
              <Grid item md={4.5} xs={12}>
                <label></label>


                <InputField placeholder="Enter Amount" inputProps={{ min: 0 }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleAddAmount(event)} value={amount1} />
                <Typography sx={{ color: 'red', fontSize: '14px' }} className='mx-1'>{isValidAmount(amount) ? 'Entered amount is invalid' : ''}</Typography>
              </Grid>
              <Grid item md={4.5} xs={12} >

                <label style={{ color: '#4C4C4C !important', opacity: 0.5 }}>{finalAmount ? "Final Amount (after 18% tax)" : null}</label>
                <InputField placeholder="Final Amount (after 18% tax)" value={finalAmount == 0 ? "" : finalAmount} disabled />
              </Grid>
              <Grid item md={3} xs={12} >
                <label></label>

                <div className='text-center mt-1 d-flex justify-content-center'>
                  {/* <Button className="EnableButton" variant="contained" onClick={handlePaymentOrderCreation} disabled={enableAdd}>Add Amount</Button> */}

                  <Button1 variant="primary" onClick={handlePaymentOrderCreation} disabled={enableAdd}>Add Amount</Button1>

                </div>
              </Grid>
            </Grid>

          </Card>
        </Grid>
      </Grid>
      <div className='mt-4'>
        <Paper elevation={0} sx={{ border: '1px solid #E1E3EB', borderRadius: '8px', minHeight: '20vh' }}>
          <div className='p-4 d-flex justify-content-between align-items-center'>
            <Typography className='fw-bold'>Transaction History </Typography>

            {/* <Button1 disabled={enableDownload}><Image src={download} alt='download' /></Button1> */}

          </div>
          {
            data && data?.length > 0 ? <DataGrid rows={data} columns={column} pageSizeOptions={[5, 10, 25, 50]} initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              }
            }} /> : <div className='d-flex justify-content-center p-4'>No Transaction History</div>
          }

        </Paper>
      </div>
    </div>
  )
}

export default Page;


